import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { StylesProvider } from "@material-ui/styles";


const ThemeProvider = ({ theme, children }) => (
  <StylesProvider injectFirst>
    <CssBaseline />
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  </StylesProvider>
);

export default ThemeProvider;