import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import React, { useState } from "react";
import { API } from "aws-amplify";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import { createGamePlayer } from "../../../../../graphql/mutations";
import Paper from '@material-ui/core/Paper';
import "./Join.css";

const onClickCreateGamePlayer = (setUser, gameId, name, error) => async (e) => {
  e.preventDefault()
  if (error) {
    return
  }
  const input = {
    name,
    gameId,
    cards: [],
    remainingLives: 5,
    hasKnocked: false,
  };
  const user = await API.graphql(graphqlOperation(createGamePlayer, { input}));
  setUser(user.data.createGamePlayer.id)
}

const Join = ({ setUser, players, gameId }) => {
  const [input, setInput] = useState('')
  const error = players.some(p => p.name === input)
  const onSubmit = onClickCreateGamePlayer(setUser, gameId, input, error, players.length);
  return <Grid item>
    <form autoComplete="off" onSubmit={onSubmit}>
      <Grid
        container
        alignItems="flex-start"
        spacing={2}
        direction="row"
      >
          <Paper
            elevation={3}
            id="join-card"
          >
            <h2>Enter your name to join the game</h2>
          <TextField
            required
            id="user"
            label="Name"
            helperText={error && "Name already taken"}
            variant="outlined"
            value={input}
            onChange={e => setInput(e.target.value)}
            error={error}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={error || input === ''}
          >
            Join
          </Button>
          </Paper>
        </Grid>
    </form>
  </Grid>;
}

export default Join