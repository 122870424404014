import React from "react";
import { ButtonBase } from "@material-ui/core";
import "./PlayingCard.css";

const PlayingCard = ({ suit, value, hidden, isTouchable, selected, selectCard, size = "small" }) => {
  if (!suit || !value) {
    return (
      <ButtonBase
        disabled={true}
        className={`PlayingCard ${size}`}
      >
        <img src={`/images/card/Empty.svg`} alt="card-top" />
      </ButtonBase>
    )
  }
  if (hidden) return (
    <ButtonBase
      disabled={true}
      className={`PlayingCard card-top ${size}`}
    >
      <img src={`/images/card/Background.svg`} alt="card-top" />
    </ButtonBase>
  )

  return (
    <ButtonBase
      className={`PlayingCard ${size} ${selected && "selected-card"}`}
      disabled={!isTouchable}
      onClick={() => isTouchable && selectCard({ suit, value })}
    >
      {value && (
        <img src={`/images/card/${size}/${suit}/${value}.svg`} alt={`${suit}-${value}`}/>
      )}
    </ButtonBase>
  );
}

export default PlayingCard