import "./App.css";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import { Router } from "@reach/router";
import Home from "./components/pages/Home";
import GamePage from "./components/pages/game/GamePage";
import ThemeProvider from "./themes/ThemeProvider";
import defaultTheme from "./themes/default-theme";

Amplify.configure(awsconfig);

const App = () => (
  <ThemeProvider theme={defaultTheme}>
    <Router>
      <Home path="/"/>
      <GamePage path="/:gameId"/>
    </Router>
  </ThemeProvider>
)

export default App;
