import React from "react";
import "./Status.css"

const Status = ({
    isActivePlayer,
    hasKnocked,
}) => {
    var status = ""
    if (isActivePlayer) status = "Playing"
    if (!isActivePlayer && hasKnocked) status = "Knocked"
    return (
        <div className={`Status ${status}`}>
            {status}
        </div>
    );
}

export default Status