/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGamePlayer = /* GraphQL */ `
  mutation CreateGamePlayer(
    $input: CreateGamePlayerInput!
    $condition: ModelGamePlayerConditionInput
  ) {
    createGamePlayer(input: $input, condition: $condition) {
      id
      name
      gameId
      order
      cards {
        suit
        value
      }
      remainingLives
      hasKnocked
      handScore
      createdAt
      updatedAt
    }
  }
`;
export const updateGamePlayer = /* GraphQL */ `
  mutation UpdateGamePlayer(
    $input: UpdateGamePlayerInput!
    $condition: ModelGamePlayerConditionInput
  ) {
    updateGamePlayer(input: $input, condition: $condition) {
      id
      name
      gameId
      order
      cards {
        suit
        value
      }
      remainingLives
      hasKnocked
      handScore
      createdAt
      updatedAt
    }
  }
`;
export const deleteGamePlayer = /* GraphQL */ `
  mutation DeleteGamePlayer(
    $input: DeleteGamePlayerInput!
    $condition: ModelGamePlayerConditionInput
  ) {
    deleteGamePlayer(input: $input, condition: $condition) {
      id
      name
      gameId
      order
      cards {
        suit
        value
      }
      remainingLives
      hasKnocked
      handScore
      createdAt
      updatedAt
    }
  }
`;
export const createGame = /* GraphQL */ `
  mutation CreateGame(
    $input: CreateGameInput!
    $condition: ModelGameConditionInput
  ) {
    createGame(input: $input, condition: $condition) {
      id
      deck {
        suit
        value
      }
      discard {
        suit
        value
      }
      hasStarted
      deal
      round
      dealer
      activePlayer
      players {
        items {
          id
          name
          gameId
          order
          cards {
            suit
            value
          }
          remainingLives
          hasKnocked
          handScore
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGame = /* GraphQL */ `
  mutation UpdateGame(
    $input: UpdateGameInput!
    $condition: ModelGameConditionInput
  ) {
    updateGame(input: $input, condition: $condition) {
      id
      deck {
        suit
        value
      }
      discard {
        suit
        value
      }
      hasStarted
      deal
      round
      dealer
      activePlayer
      players {
        items {
          id
          name
          gameId
          order
          cards {
            suit
            value
          }
          remainingLives
          hasKnocked
          handScore
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGame = /* GraphQL */ `
  mutation DeleteGame(
    $input: DeleteGameInput!
    $condition: ModelGameConditionInput
  ) {
    deleteGame(input: $input, condition: $condition) {
      id
      deck {
        suit
        value
      }
      discard {
        suit
        value
      }
      hasStarted
      deal
      round
      dealer
      activePlayer
      players {
        items {
          id
          name
          gameId
          order
          cards {
            suit
            value
          }
          remainingLives
          hasKnocked
          handScore
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
