/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGamePlayer = /* GraphQL */ `
  query GetGamePlayer($id: ID!) {
    getGamePlayer(id: $id) {
      id
      name
      gameId
      order
      cards {
        suit
        value
      }
      remainingLives
      hasKnocked
      handScore
      createdAt
      updatedAt
    }
  }
`;
export const listGamePlayers = /* GraphQL */ `
  query ListGamePlayers(
    $filter: ModelGamePlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGamePlayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        gameId
        order
        cards {
          suit
          value
        }
        remainingLives
        hasKnocked
        handScore
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGame = /* GraphQL */ `
  query GetGame($id: ID!) {
    getGame(id: $id) {
      id
      deck {
        suit
        value
      }
      discard {
        suit
        value
      }
      hasStarted
      deal
      round
      dealer
      activePlayer
      players {
        items {
          id
          name
          gameId
          order
          cards {
            suit
            value
          }
          remainingLives
          hasKnocked
          handScore
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGames = /* GraphQL */ `
  query ListGames(
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deck {
          suit
          value
        }
        discard {
          suit
          value
        }
        hasStarted
        deal
        round
        dealer
        activePlayer
        players {
          items {
            id
            name
            gameId
            order
            remainingLives
            hasKnocked
            handScore
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
