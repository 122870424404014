import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { API } from "aws-amplify";
import { createGame } from "../../graphql/mutations";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import { navigate } from "@reach/router";
import ActionButton from "./game/components/game-table/action-button/ActionButton";
import IconNew from "./game/components/game-table/action-button/icon/New.svg";
import IconJoin from "./game/components/game-table/action-button/icon/Join.svg";

const onClickCreateGame = async () => {
  let input = {
    deck: [],
    discard: [],
    hasStarted: false,
    deal: 0,
    round: 0,
  };
  const game = await API.graphql(graphqlOperation(createGame, { input }));
  await navigate(`/${game.data.createGame.id}`)
}

const Home = () => (
  <Container>
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid
        item
      >
        <h1>
          Welcome to Scat
        </h1>
      </Grid>
      <Grid
        item
        spacing={2}
      >
        <ActionButton
          text={"New Game"}
          icon={IconNew}
          onClick={onClickCreateGame}
        >
        </ActionButton>
      </Grid>
      <Grid item>
        <ActionButton
          text={"join Game"}
          icon={IconJoin}
        >
        </ActionButton>
      </Grid>
    </Grid>
  </Container>
)

export default Home