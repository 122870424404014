import React from "react";
import DealerChip from "./DealerChip.svg";

const Dealer = ({ test }) => (
        <div className={"Dealer"}>
         <img src={DealerChip} alt="Dealer Chip" />
            <div className={"DealerTag"}>Dealer</div>
        </div>
)

export default Dealer