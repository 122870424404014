import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Player from "../player/Player";
import Join from "../join/Join";
import PlayingCard from "./card/PlayingCard";
import CenterActions from "../CenterActions";
import splitWhen from "ramda/src/splitWhen";
import propEq from "ramda/src/propEq";
import prop from "ramda/src/prop";
import reverse from "ramda/src/reverse";
import concat from "ramda/src/concat";
import { any, equals, find, head, length, map, pluck, sortWith, ascend, without, reject, splitAt } from "ramda";
import Container from "@material-ui/core/Container";

const isDealer = (dealerId, { id }) => dealerId === id

const anyScat = players => any(propEq("handScore", 31))(players) && !any(equals(4), map(length, pluck("cards", players)))

export const sortPlayers = sortWith([
  ascend(prop('createdAt')),
  ascend(prop('id'))
])

const Game = ({ game, players: unsortedPlayers, user, setUser }) => {
  const [ isDealing, setDealing ] = useState(false)
  const players = sortPlayers(unsortedPlayers)
  const [ beforeYou, [ you, ...afterYou ] ] = splitWhen(propEq('id', user), players)
  const otherPlayers = concat(afterYou, beforeYou);
  const [ leftPlayers, rightPlayers ] = splitAt(Math.ceil(otherPlayers.length / 2), otherPlayers);
  const drawCard = head(game.discard)
  const knocker = find(propEq('hasKnocked', true))(players)
  const activePlayer = game.activePlayer
  const roundOver = !isDealing && ((knocker && knocker.id === activePlayer) || anyScat(players))
  const dealer = find(propEq('id', game.dealer), players);

  const [ selectedCard, selectCard ] = useState(false)

  return (
    <Container maxWidth={"lg"}>
    <Grid
      container
      spacing={2}
      alignItems="stretch"
    >
      <Grid
        item
        xs={3}
      >
            {reverse(leftPlayers).map(p => (
                <Player
                  {...p}
                  key={p.id}
                  isDealer={isDealer(game.dealer, p)}
                  isActivePlayer={activePlayer === p.id}
                  roundOver={roundOver}
                  tableSide={"left"}
                />
            ))}
          </Grid>
      <Grid
        container
        item
        xs={6}
        justify="center"
      >
        <Grid
          item
        >
          <div className="DrawPile">
              <Grid
                container
                direction="row"
                justify="center"
              >
                <PlayingCard
                  hidden
                  {...head(game.deck)}
                  size="large"
                />
                <PlayingCard {...drawCard} size="large"/>
              </Grid>
          </div>
            <Grid
                item
            >
              {!you && !game.hasStarted && (
                <Grid
                  container
                  alignItems="flex-start"
                >
                  <Join
                    setUser={setUser}
                    players={players}
                    gameId={game.id}
                  />
                </Grid>
              )}
              <CenterActions
                game={game}
                you={you}
                allPlayers={players}
                selectedCard={selectedCard}
                knocker={knocker}
                roundOver={roundOver}
                dealer={dealer}
                setSelectedCard={selectCard}
                isDealing={isDealing}
                setDealing={setDealing}
              />
            </Grid>
            <Grid
                container
                direction="row"
            >
              {you && (
                <Player
                  {...you}
                  isDealer={isDealer(game.dealer, you)}
                  selectedCard={selectedCard}
                  selectCard={selectCard}
                  isYou={true}
                  isActivePlayer={activePlayer === you.id}
                  roundOver={roundOver}
                  tableSide={"center"}
                />
              )}
            </Grid>
          </Grid>
      </Grid>
        <Grid
            item
            container
            direction="column"
            xs={3}
        >
            {rightPlayers.map(p => (
                <Player
                  {...p}
                  key={p.id}
                  isDealer={isDealer(game.dealer, p)}
                  isActivePlayer={activePlayer === p.id}
                  roundOver={roundOver}
                  tableSide={"right"}
                />
            ))}
          </Grid>
    </Grid>
    </Container>
  );
}

export default Game