import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import "./ActionButton.css"

const ActionButton = ({
    icon,
    text,
    onClick
}) => (
    <ButtonBase className={"action-button"} onClick={onClick}>
        <img src={icon} />
        <div className={"action-text"}>{text}</div>
    </ButtonBase>
)

export default ActionButton