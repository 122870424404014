import Grid from "@material-ui/core/Grid";
import React from "react";
import { API } from "aws-amplify";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import { updateGame, updateGamePlayer } from "../../../../graphql/mutations";
import append from "ramda/src/append";
import reject from "ramda/src/reject";
import useSound from "use-sound";
import {
  curry,
  dec,
  equals,
  head,
  identity,
  ifElse,
  inc,
  indexOf,
  lensProp,
  map,
  over,
  prepend,
  propEq,
  range,
  set,
  prop,
  sortBy,
  isEmpty,
  propSatisfies,
  flip,
  gt,
  any,
  length,
  pluck, find,
} from "ramda";
import ActionButton from "./game-table/action-button/ActionButton";
import NextRound from "./game-table/action-button/icon/NextRound.svg";
import IconKnock from "./game-table/action-button/icon/Knock.svg";
import NewGame from "./game-table/action-button/icon/New.svg";
import NoiseKnock from './game-table/action-button/Knock.mp3';
import Button from "@material-ui/core/Button";
import { sortPlayers } from "./game-table/Game";

const shuffler = curry((random, list) => {
  const len = list.length;
  const result = [];
  let idx = -1;
  let position;
  while (++idx < len) {
    position = Math.floor((idx + 1) * random());
    result[idx] = result[position];
    result[position] = list[idx];
  }
  return result;
})
const shuffle = shuffler(Math.random);

// TODO duplicate code in GamePage
const findScatter = find(propEq("handScore", 31))
const anyScat = players => any(propEq("handScore", 31))(players) && !any(equals(4), map(length, pluck("cards", players)))

const isPlayerActive = propSatisfies(flip(gt)(0), "remainingLives")
const filterActivePlayers = allPlayers => reject(propEq("remainingLives", 0))(allPlayers)
const findNextActivePlayer = (player, allPlayers) => {
  const activePlayers = sortPlayers(filterActivePlayers(allPlayers))
  const nextPlayerIndex = indexOf(activePlayers.find(p => p.id === player.id), activePlayers) + 1
  return activePlayers[nextPlayerIndex >= activePlayers.length ? 0 : nextPlayerIndex]
}

const getSuit = (index) => {
  return ({
    0: 'diamond',
    1: 'club',
    2: 'spade',
    3: 'heart',
  })[index]
}

const getValue = (index) => {
  const stringCard = ({
    0: 'A',
    10: 'J',
    11: 'Q',
    12: 'K',
  })[index]
  if (!stringCard) {
    return `${index + 1}`
  }
  return stringCard
}

const createCard = (index) => ({
  suit: getSuit(Math.floor(index / 13)),
  value: getValue(index % 13)
})

const createCards = () => {
  return map(createCard, shuffle(shuffle(range(0,52))))
}

const resetCards = set(lensProp("cards"), [])
const resetAllCards = map(resetCards)
const startGame = (game, players, you, nextDealer, setDealing) => async () => {
  setDealing(true)
  const deck = createCards();
  const playersWithCardsReset = resetAllCards(players)
  const drawCard = ifElse(
    propSatisfies(flip(gt)(0), "remainingLives"),
    over(lensProp("cards"), (c) => append(deck.shift(), c)),
    identity
  )
  // TODO - make this better
  const playersWithDrawnCards = map(drawCard, map(drawCard, map(drawCard, playersWithCardsReset)))
  const discard = [deck.shift()];

  await API.graphql(graphqlOperation(updateGame, {
    input: {
      id: game.id,
      deal: inc(game.deal),
      round: 1,
      hasStarted: true,
      deck,
      discard,
      dealer: nextDealer.id,
      activePlayer: findNextActivePlayer(you, players).id,
    }
  }));

  for (const { id, cards, remainingLives } of playersWithDrawnCards) {
    const updateGamePlayerInput = {
      input: {
        id,
        cards,
        remainingLives,
        hasKnocked: false,
      }
    };
    await API.graphql(graphqlOperation(updateGamePlayer, updateGamePlayerInput));
  }
  setDealing(false)
}

const dropLife = over(lensProp("remainingLives"), dec)
const startNextRound = (game, players, you, currentDealer, setDealing) => async () => {
  const activePlayers = filterActivePlayers(players)
  const lowestScore = head(sortBy(prop("handScore"))(activePlayers)).handScore
  const dropLifeIfLoser = ifElse(
    propEq("handScore", lowestScore),
    dropLife,
    identity
  )
  const dropLifeIfActive = ifElse(
    p => isPlayerActive(p) && p.id !== findScatter(activePlayers).id, // TODO - this needs to change when someone can claim scat at any time
    dropLife,
    identity
  )
  const updatedPlayersForLoser = anyScat(activePlayers) ? map(dropLifeIfActive, players) : map(dropLifeIfLoser, players)
  const nextDealer = findNextActivePlayer(currentDealer, players);

  await startGame(game, updatedPlayersForLoser, you, nextDealer, setDealing)();
}

const draw = (game, fromDiscard, you, currentDealer, setSelectedCard) => async () => {
  const newCard = fromDiscard ? game.discard.shift() : game.deck.shift();
  setSelectedCard(newCard)
  const updateGamePlayerInput = {
    input: {
      id: you.id,
      cards: append(newCard, you.cards)
    }
  };
  // TODO - optimize this
  const updateGameInput = {
    input: {
      id: game.id,
      deck: game.deck,
      discard: game.discard,
      round: equals(currentDealer, you) ? inc(game.round) : game.round,
    }
  };
  await API.graphql(graphqlOperation(updateGamePlayer, updateGamePlayerInput));
  await API.graphql(graphqlOperation(updateGame, updateGameInput));
}

const discard = (game, you, selectedCard, allPlayers) => async () => {
  const updateGamePlayerInput = {
    input: {
      id: you.id,
      cards: reject(equals(selectedCard), you.cards)
    }
  };
  // TODO - make this nicer
  const optionalUpdates = {}
  if (isEmpty(game.deck)) {
    optionalUpdates["deck"] = shuffle(shuffle(game.discard))
    optionalUpdates["discard"] = [selectedCard]
  }
  const updateGameInput = {
    input: {
      id: game.id,
      discard: prepend(selectedCard, game.discard),
      activePlayer: findNextActivePlayer(you, allPlayers).id,
      ...optionalUpdates
    }
  };
  await API.graphql(graphqlOperation(updateGame, updateGameInput));
  await API.graphql(graphqlOperation(updateGamePlayer, updateGamePlayerInput));
}

const knock = (game, you, allPlayers) => async () => {
  const updateGamePlayerInput = {
    input: {
      id: you.id,
      hasKnocked: true
    }
  };
  const updateGameInput = {
    input: {
      id: game.id,
      activePlayer: findNextActivePlayer(you, allPlayers).id,
    }
  };
  await API.graphql(graphqlOperation(updateGame, updateGameInput));
  await API.graphql(graphqlOperation(updateGamePlayer, updateGamePlayerInput));
}

const winner = allPlayers => {
  const activePlayers = filterActivePlayers(allPlayers);
  if (activePlayers.length === 1) {
    return head(activePlayers)
  }
}

const CenterActions = ({
  game,
  you,
  allPlayers,
  selectedCard,
  setSelectedCard,
  knocker,
  roundOver,
  dealer,
  isDealing,
  setDealing,
}) => {
  if (!you || winner(allPlayers) || isDealing) return null
  else if (roundOver) {
    return (findNextActivePlayer(dealer, allPlayers) === you) && (
      <Grid
        container
        direction="row"
        justify="space-evenly"
      >
        <ActionButton
          icon={NextRound}
          text={"Next Round"}
          onClick={startNextRound(game, allPlayers, you, dealer, setDealing)}
        />
      </Grid>
    )
  }
  else if (!game.hasStarted) {
    return (
      <Grid
        container
        direction="row"
        justify="space-evenly"
      >
        <ActionButton
          icon={NewGame}
          text={"Start"}
          onClick={startGame(game, allPlayers, you, you, setDealing)}
        />
      </Grid>
    )
  } else if (game.activePlayer === you.id) {
    if (you.cards.length > 3) {
      return (
        <Grid
          container
          justify="space-around"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={discard(game, you, selectedCard, allPlayers)}
            disabled={!selectedCard}
          >
            Discard
          </Button>
        </Grid>
      )
    } else {
      return (
        <Grid
          container
          justify="space-around"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={draw(game, false, you, dealer, setSelectedCard)}
          >
            Draw
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={draw(game, true, you, dealer, setSelectedCard)}
          >
            Pickup
          </Button>
          {!knocker && game.round > 1 && (
            <Grid
              container
              justify="space-around"
            >
              <ActionButton
                icon={IconKnock}
                text={"Knock"}
                onClick={
                  knock(game, you, allPlayers)
                }
              />
            </Grid>
          )}
        </Grid>
      )
    }
  }
  return null
}

export default CenterActions;