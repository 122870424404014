import React, { Fragment } from "react";
import "./Player.css";
import Life from "./life/Life";
import PlayingCard from "../game-table/card/PlayingCard";
import Dealer from "./dealer/Dealer";
import Grid from "@material-ui/core/Grid";
import { equals } from "ramda";
import Status from "./status/Status";

const Player = ({
    name,
    tableSide,
    remainingLives,
    cards = [],
    isActivePlayer,
    isDealer,
    selectCard,
    selectedCard,
    isYou = false,
    roundOver,
    handScore,
    hasKnocked,
}) => (
  <Fragment>
    <div className={`table ${tableSide}`}>
    <Status hasKnocked={hasKnocked} isActivePlayer={isActivePlayer}/>
    <h2 className={`PlayerName ${remainingLives === 0 ? "PlayerNameOut" : ""}`}>
        {name}
        <div className={"hand-score"}>
            {roundOver && ` (${handScore} points)`}
        </div>
      {isDealer && <Dealer />}
    </h2>
    <div className={"life-container"}>
    {Array.from({ length: remainingLives - 1 }, (_, i) => (
          <Life key={i}/>
    ))}
    </div>
    <Grid
        container
        direction={tableSide === "right" ? "row-reverse" : "row"}
    >
      {cards.map(card => (
        <PlayingCard
          key={`${card.suit}-${card.value}`}
          {...card}
          isTouchable={isYou}
          selected={equals(selectedCard, card)}
          selectCard={selectCard}
          hidden={!roundOver && !isYou}
          size={isYou ? "large" : "small"}
        />
      ))}
    </Grid>
    </div>
  </Fragment>
)

export default Player