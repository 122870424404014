/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateGame = /* GraphQL */ `
  subscription OnUpdateGame($id: ID) {
    onUpdateGame(id: $id) {
      id
      deck {
        suit
        value
      }
      discard {
        suit
        value
      }
      hasStarted
      deal
      round
      dealer
      activePlayer
      players {
        items {
          id
          name
          gameId
          order
          cards {
            suit
            value
          }
          remainingLives
          hasKnocked
          handScore
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGamePlayer = /* GraphQL */ `
  subscription OnUpdateGamePlayer($gameId: ID) {
    onUpdateGamePlayer(gameId: $gameId) {
      id
      name
      gameId
      order
      cards {
        suit
        value
      }
      remainingLives
      hasKnocked
      handScore
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGamePlayer = /* GraphQL */ `
  subscription OnCreateGamePlayer($gameId: ID) {
    onCreateGamePlayer(gameId: $gameId) {
      id
      name
      gameId
      order
      cards {
        suit
        value
      }
      remainingLives
      hasKnocked
      handScore
      createdAt
      updatedAt
    }
  }
`;
