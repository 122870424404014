import React, { Fragment, useEffect, useRef, useState } from "react";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import { useParams } from "@reach/router";
import { getGame } from "../../../graphql/queries";
import Game from "./components/game-table/Game";
import { Grid } from "@material-ui/core";
import { onCreateGamePlayer, onUpdateGame, onUpdateGamePlayer } from "../../../graphql/subscriptions";
import { API } from "@aws-amplify/api";
import { map, none, propEq } from "ramda";
import append from "ramda/src/append";
import ConnectUserState from "./ConnectUserState";
import Snackbar from "@material-ui/core/Snackbar";


const GamePage = () => {
  const { gameId } = useParams();
  const [ players, setPlayers ] = useState([])
  const [ game, setGame ] = useState()
  const [ notification, setNotification ] = useState()
  const playersRef = useRef(players)
  playersRef.current = players

  useEffect(() => {
    const onCreateGamePlayerSubscription = API.graphql(graphqlOperation(onCreateGamePlayer, { gameId }))
    .subscribe({
      next: ({ value: { data: { onCreateGamePlayer: newPlayer } } }) => {
        setPlayers(append(newPlayer, playersRef.current))
      }
    });
    const onUpdateGamePlayerSubscription = API.graphql(graphqlOperation(onUpdateGamePlayer, { gameId }))
    .subscribe({
      next: ({ value: { data: { onUpdateGamePlayer: player } } }) => {
        if (none(propEq('hasKnocked', true), playersRef.current) && player.hasKnocked) {
          setNotification(`${player.name} has knocked!!`.toUpperCase())
        }
        setPlayers(map(a => a.id === player.id ? player : a, playersRef.current))
      }
    });
    const onUpdateGameSubscription = API.graphql(graphqlOperation(onUpdateGame, { id: gameId }))
    .subscribe({
      next: ({ value: { data: { onUpdateGame: game } } }) => {
        setGame(game)
      }
    });

    (async () => {
      const { data: { getGame: game } } = await API.graphql(graphqlOperation(getGame, { id: gameId }))
      setGame(game)
      setPlayers(game.players.items)
    })()

    return () => {
      onCreateGamePlayerSubscription.unsubscribe()
      onUpdateGamePlayerSubscription.unsubscribe()
      onUpdateGameSubscription.unsubscribe()
    }
  }, [ gameId ])
  if (!game) return null
  return (
    <Fragment>
      <ConnectUserState gameId={gameId}>
        {(userStuff) => (
          <Game
            game={game}
            players={players}
            notify={setNotification}
            {...userStuff}
          />
        )}
      </ConnectUserState>
      <Snackbar
        open={!!notification}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setNotification(undefined)}
        message={notification}
      />
    </Fragment>
  );
}

export default GamePage